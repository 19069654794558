import request from '@/utils/request'


// 查询用户点赞行为记录列表
export function listLikes(query) {
  return request({
    url: '/user/user-likes/list',
    method: 'get',
    params: query
  })
}

// 查询用户点赞行为记录分页
export function pageLikes(query) {
  return request({
    url: '/user/user-likes/page',
    method: 'get',
    params: query
  })
}

// 查询用户点赞行为记录详细
export function getLikes(data) {
  return request({
    url: '/user/user-likes/detail',
    method: 'get',
    params: data
  })
}

// 新增用户点赞行为记录
export function addLikes(data) {
  return request({
    url: '/user/user-likes/add',
    method: 'post',
    data: data
  })
}

// 修改用户点赞行为记录
export function updateLikes(data) {
  return request({
    url: '/user/user-likes/edit',
    method: 'post',
    data: data
  })
}

// 删除用户点赞行为记录
export function delLikes(data) {
  return request({
    url: '/user/user-likes/delete',
    method: 'post',
    data: data
  })
}
